import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components';

/**
 * Converts a YouTube Shorts URL to a normal YouTube video URL.
 * Otherwise, returned as is.
 *
 * @param {string} url - The YouTube Shorts URL to convert.
 * @returns {string} The converted URL.
 */
export const convertYoutubeShortsToNormalURL = (url) => {    
  if (url.includes("youtube.com") && url.includes("/shorts/")) {
    const videoId = url.split("/shorts/")[1];
    const normalURL = `https://www.youtube.com/watch?v=${videoId}`;
    return normalURL;
  }

  return url;
};


const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 30px;

  @media screen and (max-width: 767px) {
    /* Make player landscape on mobile as well */
    > div {
      width: calc(100vw - 32px) !important;
      height: calc(0.5625 * calc(100vw - 32px)) !important;
    }
  }
`;


const AboutMediaEmbed = ({ url }) => {
  // Allow youtube shorts to be used
  const convertedURL = convertYoutubeShortsToNormalURL(url)

  return (
    <PlayerWrapper>
      <ReactPlayer width="100%" height='460px' url={convertedURL} controls />
    </PlayerWrapper>
)}

export default AboutMediaEmbed
