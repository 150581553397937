/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { updateFormField } from '../client/subscribe/actions';

function DefaultTOS({ textColor, isV3, styles }) {
  // This way TOS is accepted atomatically when it is enabled
  // when there is no customTOS URL set for the community
  useEffect(() => {
    store.dispatch(updateFormField('tosAccepted', true));
  }, []);

  return (
    <div>
      <label
        className={isV3 && styles.tosLabel}
        style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
        htmlFor="tos"
      >
        By completing signup, I agree to the&nbsp;
        <a
          href="https://www.launchpass.com/terms"
          className={isV3 && styles.tosLink}
          style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
          target="_blank"
          rel="noreferrer"
        >
          terms & conditions
        </a>
        &nbsp;of LaunchPass.
      </label>
    </div>
  );
}

export default DefaultTOS;
