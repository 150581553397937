import numbro from 'numbro'

import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from 'config'
import * as reducers from './reducers'
import StripeFormElements from '../components/StripeFormElements'
import StripeFormElementsV2 from '../components/StripeFormElementsV2'

import EmailFormV2 from '../components/EmailFormV2'
import AboutMediaEmbed from '../components/AboutMediaEmbed'

import { formatPrice, getNumber } from '../utils'
import 'url-search-params-polyfill'

const BugsnagSettings = config.bugsnagSettings;
BugsnagSettings.releaseStage = config.environment;
BugsnagSettings.appType = 'client'
BugsnagSettings.appVersion = config.version

BugsnagSettings.plugins = [new BugsnagPluginReact()]
Bugsnag.start(BugsnagSettings)
console.log(`launchpass v${config.version} client ${config.environment}`);

const setInitialState = (state) => {
  // console.log('initialState', JSON.stringify(state, null, 2));
  if (state.selector) {
    if (!state.selector.period) {
      state.selector.period = state.page.period
    }

    if (state.page.period !== 'free') {
      /*if (state.selector.price) {
        const price = formatPrice(getNumber(state.selector.price), state.selector.currency)
        state.selector.price = price
        state.selector.currentPrice = price
        if (state.selector.period == 'monthly') {
          state.selector.monthlyPrice = price
        }
        if (state.selector.period == 'yearly') {
          state.selector.yearlyPrice = price
        }
      }

      if (state.selector.alternatePayment) {
        const alternatePaymentAmount = formatPrice(getNumber(state.selector.alternatePaymentAmount), state.selector.currency)

        if (state.selector.period === 'monthly') {
          state.selector.monthlyPrice = state.selector.price
          state.selector.yearlyPrice = alternatePaymentAmount
        }
        if (state.selector.period === 'yearly') {
          state.selector.monthlyPrice = alternatePaymentAmount
          state.selector.yearlyPrice = state.selector.price
        }
        if (state.selector.yearlySelected) {
          state.selector.currentPrice = state.selector.yearlyPrice
        } else {
          state.selector.currentPrice = state.selector.monthlyPrice
        }*/
      }
      if (state.selector.joiningPayment) {
        /*let joiningPaymentAmount = state.selector.joiningPaymentAmount.toString()
        joiningPaymentAmount = joiningPaymentAmount.indexOf('$') === 0 ? joiningPaymentAmount : numbro(joiningPaymentAmount).formatCurrency('$0,0.00')
        state.selector.joiningPaymentAmount = joiningPaymentAmount*/
      }
    // }
  }

  // TODO add polyfill for document.location.search and URLSearchParams
  const params = new URLSearchParams(document.location.search);
  const type = params.get('s');
  const sessionId = params.get('session_id');
  if (type == '1' && sessionId) {
    state.form.subscribing = true;
    state.form.session_id = sessionId;
  }
  state.stripe = null;
  state.enableBeta = params.get('enableBeta')
  return state
}

const store = window.store = createStore(
  combineReducers(reducers),
  setInitialState(initialState),
  composeWithDevTools(
    applyMiddleware(thunk),
  ),
)

const renderStripeForm = () => {
  const state = store.getState()
  const embedmatch = '/iframe'
  const previewPath = '/preview'
  const v3match = '/v3'

  const previewmatches = document.location.pathname.substr(-previewPath.length, previewPath.length) === previewPath;
  const embedmatches = document.location.pathname.substr(-embedmatch.length, embedmatch.length)
    === embedmatch
  const v3matches = document.location.pathname.substr(-v3match.length, v3match.length)
    === v3match

  if (embedmatches || v3matches) {
    if (state.page.period === 'free') {
      ReactDOM.render(
        <EmailFormV2
          preview={previewmatches}
          errors={state.form.errors}
          openModal={state.openModal}
          displayName={state.page.displayName}
          styles={styles.EmailFormV2}
          modalStyles={styles}
          group={state.page.group}
          subscribing={state.form.subscribing}
          backgroundColor={state.page.backgroundColor}
          buttonColor={state.page.buttonColor}
          textColor={state.page.textColor}
          offerName={state.page.offerName}
          email={state.params.email}
          emailState={state.form.email}
          coupon={state.params.coupon}
          platform={state.page.platform}
        />,
        document.getElementById('free-form'),
      )
      if (state.page.mediaEmbed && !embedmatches) {
        ReactDOM.render(
          <AboutMediaEmbed url={state.page.mediaEmbed} />,
          document.getElementById('media-embed'),
        )
      }
    } else {
      ReactDOM.render(
        <StripeFormElementsV2
          preview={previewmatches}
          isEmbed={embedmatches}
          enableBeta={state.enableBeta}
          url={state.page.url}
          channel={state.page.channel}
          group={state.page.group}
          price={state.selector.price}
          currency={state.selector.currency}
          priceYearly={state.selector.priceYearly}
          yearlySelected={state.selector.yearlySelected}
          currentPrice={state.selector.currentPrice}
          monthlyPrice={state.selector.monthlyPrice}
          yearlyPrice={state.selector.yearlyPrice}
          errors={state.form.errors}
          subscribing={state.form.subscribing}
          period={state.page.period}
          trialPeriod={state.page.trialPeriod}
          openModal={state.openModal}
          styles={styles.StripeFormElementsV2}
          displayName={state.page.displayName}
          image={state.page.image}
          modalStyles={styles}
          backgroundColor={state.page.backgroundColor}
          buttonColor={state.page.buttonColor}
          textColor={state.page.textColor}
          status={state.page.status}
          alternatePayment={state.selector.alternatePayment}
          alternatePaymentAmount={state.selector.alternatePaymentAmount}
          joiningPayment={state.selector.joiningPayment}
          joiningPaymentAmount={state.selector.joiningPaymentAmount}
          waiveJoiningFeeForYearlyOption={state.page.waiveJoiningFeeForYearlyOption}
          couponsEnabled={state.coupon.couponsEnabled}
          verifyEmailEnabled={state.page.verifyEmailEnabled}
          useOauthEnabled={state.page.useOauthEnabled}
          requireNameEnabled={state.page.requireNameEnabled}
          requireAddressEnabled={state.page.requireAddressEnabled}
          customSetting1Enabled={state.page.customSetting1Enabled}
          customSetting2Enabled={state.page.customSetting2Enabled}
          customSetting3Enabled={state.page.customSetting3Enabled}
          couponFieldVisible={state.coupon.couponFieldVisible}
          couponValid={state.coupon.valid} // vs couponValid
          couponId={state.coupon.id}
          couponData={state.coupon}
          couponAmountOff={state.coupon.amount_off}
          couponPercentOff={state.coupon.percent_off}
          couponDurationInMonths={state.coupon.duration_in_months}
          couponCurrency={state.coupon.currency}
          couponDuration={state.coupon.duration}
          couponSubmitted={state.coupon.couponSubmitted}
          couponValidating={state.coupon.couponValidating}
          customTOS={state.page.customTOS}
          email={state.params.email}
          coupon={state.params.coupon}
          stripe={state.stripe}
          platform={state.page.platform}
          coinbaseEnabled={state.page.coinbaseEnabled}
          coinbaseCharge={state.coinbase.charge}
          offerName={state.page.offerName}
        />,
        document.getElementById('stripe-form'),
      )
      if (state.page.mediaEmbed && !embedmatches) {
        ReactDOM.render(
          <AboutMediaEmbed url={state.page.mediaEmbed} />,
          document.getElementById('media-embed'),
        )
      }
    }
  } else if (state.page.period === 'free') {
    ReactDOM.render(
      <EmailFormV2
        preview={previewmatches}
        errors={state.form.errors}
        openModal={state.openModal}
        displayName={state.page.displayName}
        styles={styles.EmailFormV2}
        modalStyles={styles}
        group={state.page.group}
        subscribing={state.form.subscribing}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        offerName={state.page.offerName}
        email={state.params.email}
        emailState={state.form.email}
        coupon={state.params.coupon}
        platform={state.page.platform}
      />,
      document.getElementById('free-form'),
    )
    if (state.page.mediaEmbed && !embedmatches) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}
        />,
        document.getElementById('media-embed'),
      )
    }
  } else {
    ReactDOM.render(
      <StripeFormElements
        displayName={state.page.displayName}
        enableBeta={state.enableBeta}
        url={state.page.url}
        channel={state.page.channel}
        group={state.page.group}
        price={state.selector.price}
        currency={state.selector.currency}
        priceYearly={state.selector.priceYearly}
        yearlySelected={state.selector.yearlySelected}
        currentPrice={state.selector.currentPrice}
        errors={state.form.errors}
        subscribing={state.form.subscribing}
        period={state.page.period}
        trialPeriod={state.page.trialPeriod}
        openModal={state.openModal}
        styles={styles.StripeForm}
        modalStyles={styles}
        backgroundColor={state.page.backgroundColor}
        buttonColor={state.page.buttonColor}
        textColor={state.page.textColor}
        status={state.page.status}
        alternatePayment={state.selector.alternatePayment}
        alternatePaymentAmount={state.selector.alternatePaymentAmount}
        joiningPayment={state.selector.joiningPayment}
        joiningPaymentAmount={state.selector.joiningPaymentAmount}
        waiveJoiningFeeForYearlyOption={state.page.waiveJoiningFeeForYearlyOption}
        couponsEnabled={state.coupon.couponsEnabled}
        verifyEmailEnabled={state.page.verifyEmailEnabled}
        useOauthEnabled={state.page.useOauthEnabled}
        requireNameEnabled={state.page.requireNameEnabled}
        requireAddressEnabled={state.page.requireAddressEnabled}
        customSetting1Enabled={state.page.customSetting1Enabled}
        customSetting2Enabled={state.page.customSetting2Enabled}
        customSetting3Enabled={state.page.customSetting3Enabled}
        couponFieldVisible={state.coupon.couponFieldVisible}
        couponValid={state.coupon.valid} // vs couponValid
        couponId={state.coupon.id}
        couponAmountOff={state.coupon.amount_off}
        couponPercentOff={state.coupon.percent_off}
        couponDurationInMonths={state.coupon.duration_in_months}
        couponCurrency={state.coupon.currency}
        couponDuration={state.coupon.duration}
        couponSubmitted={state.coupon.couponSubmitted}
        couponData={state.coupon}
        couponValidating={state.coupon.couponValidating}
        customTOS={state.page.customTOS}
        email={state.params.email}
        coupon={state.params.coupon}
        stripe={state.stripe}
        platform={state.page.platform}
        coinbaseEnabled={state.page.coinbaseEnabled}
        coinbaseCharge={state.coinbase.charge}
      />,
      document.getElementById('stripe-form'),
    )
    if (state.page.mediaEmbed && !embedmatches) {
      ReactDOM.render(
        <AboutMediaEmbed
          url={state.page.mediaEmbed}
        />,
        document.getElementById('media-embed'),
      )
    }
  }
}

store.subscribe(renderStripeForm)
renderStripeForm()
