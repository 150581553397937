import React, { useEffect, useState } from 'react'
import { PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'

import CoinbaseModal from './CoinbaseModal'
import {
  displayErrors,
  updateFormField,
  validateAndPostSubscriptionElements,
  processCoinbasePayment,
} from '../client/subscribe/actions'
import { getDiscountedAmount, getNumber } from '../utils';

const Button = styled.button`
  padding: 1rem 2rem 1rem 3rem;
  color: #fff;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #0052ff;
  background-repeat: no-repeat;
  background-position: 1.5rem center;
  width: 100%;
  margin-top: 5px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const CryptoTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 170px;
  justify-content: center;
`

const CryptoText = styled.div`
  padding-right: 35px;
`

const CryptoActiveIcon = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.26758 12.5848C2.26758 7.01072 6.71767 2.45568 12.2984 2.48254C18.2375 2.51023 22.4766 7.38401 22.404 12.7141C22.3309 18.0756 18.1087 22.6364 12.2893 22.6774C6.69967 22.717 2.2673 18.1913 2.26758 12.5848Z"
      fill="#0052FF"
    />
    <path
      d="M9.19338 6.36659C11.028 5.37134 13.3222 5.29159 15.2302 6.12761C17.2653 6.99714 18.7903 8.8824 19.2904 11.0294C18.1885 11.0324 17.085 11.069 15.9845 11.0086C15.3318 9.18092 13.0739 8.2117 11.2667 8.84474C9.66636 9.34153 8.46259 10.9734 8.58832 12.6662C8.56782 14.6551 10.3606 16.4257 12.3414 16.4207C13.9259 16.5115 15.4495 15.4717 16.0482 14.0228C17.1296 14.0148 18.2082 14.0118 19.2904 14.0245C18.8955 15.7389 17.8745 17.3326 16.3922 18.3007C14.7307 19.4776 12.5358 19.7944 10.5852 19.2658C8.73979 18.7515 7.114 17.46 6.2525 15.742C5.35279 14.0223 5.21904 11.9221 5.9166 10.1083C6.49619 8.51299 7.69359 7.15803 9.19338 6.36659Z"
      fill="white"
    />
  </svg>
)

function QuickPay({
  currency,
  stripe,
  channel,
  period,
  offerName,
  coinbaseEnabled,
  coinbaseCharge,
  modalStyles,
  styles,
  openModal,
  yearlySelected,
  joiningPayment,
  joiningPaymentAmount,
  waiveJoiningFeeForYearlyOption,
  isEmbed,
  formattedPrices,
}) {
  // Crypto
  const handleCryptoPayment = () => {
    store.dispatch(
      validateAndPostSubscriptionElements({
        provider: 'coinbase',
      }, 'v3')
    )
  }

  const processCryptoPayment = (data) => {
    store.dispatch(processCoinbasePayment(data, coinbaseCharge, 'v3'))
  }

  const handlePaymentClick = async (event) => {
    const errors = []
    const { form, page, coupon } = store.getState()

    if (page.customTOS && (form.tosAccepted === undefined || form.tosAccepted === false)) {
      errors.push({ field: 'customTOS', message: 'Please accept our terms of service.' })
    }

    if (errors.length > 0) {
      event.preventDefault()
      console.log('found errors', errors)
      store.dispatch(displayErrors(errors))
      return false
    }
    const price = getNumber(formattedPrices.currentPriceAfterCoupon)
    

    if (pr) {
      await pr.update({
        total: {
          label,
          amount: (!page.trialPeriod || !parseInt(page.trialPeriod)) ? Number((getNumber(formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0)) : 0,
        },
      })
    }

    store.dispatch(updateFormField('paymentRequest', pr))
    return true
  }
  const onPaymentMethodReceived = async (ev) => {
    // console.log('payment method recieved', ev)
    store.dispatch(updateFormField('paymentMethod', ev))
    store.dispatch(updateFormField('paymentMethodId', ev.paymentMethod.id))
    store.dispatch(updateFormField('email', ev.payerEmail))
    store.dispatch(updateFormField('emailConfirm', ev.payerEmail.id))

    store.dispatch(
      validateAndPostSubscriptionElements({
        provider: 'stripe',
      }, 'v3', isEmbed)
    )
  }
   
  const [pr, setPr] = useState(null)
  const label = `${offerName || channel} ${period || 'monthly'}`
  // console.log('got country', state.page.country)
  useEffect(() => {
    const { form, page, coupon } = store.getState()

    // const state = store.getState()
    if (stripe) {
      const _pr = stripe.paymentRequest({
        country: page.country || 'US',
        currency,
        total: {
          label,
          amount: (!page.trialPeriod || !parseInt(page.trialPeriod)) ? Number((getNumber(formattedPrices.currentPriceAfterCoupon) * 100).toFixed(0)) : 0,
        },
        requestPayerEmail: true,
      })

      // Check the availability of the Payment Request API.
      _pr.canMakePayment().then((result) => {
        if (result) {
          // console.log('payment request created')
          setPr(_pr)
          _pr.on('paymentmethod', onPaymentMethodReceived)
        } else {
          console.log('cant use PaymentRequest', result)
        }
      })
    }
  }, [stripe])

  if (pr || coinbaseEnabled) {
    return (
      <div>
        <div style={{ margin: 'auto', marginTop: '5px', width: '100%', marginBottom: '24px' }}>
          {pr && (
            <PaymentRequestButtonElement
              onClick={handlePaymentClick}
              options={{ paymentRequest: pr }}
            />
          )}
          {coinbaseEnabled && (
            <Button type="button" onClick={handleCryptoPayment}>
              <CryptoTextWrapper>
                {<CryptoActiveIcon />}
                <CryptoText>Coinbase Pay</CryptoText>
              </CryptoTextWrapper>
            </Button>
          )}
        </div>

        {/* Coinbase Modals */}
        {openModal &&
          (openModal.modalType === 'coinbase' ||
            openModal.modalType === 'coinbase-close') && (
            <CoinbaseModal
              styles={styles}
              modalStyles={modalStyles}
              openModal={openModal}
              charge={openModal.modalProps}
              handlePayment={processCryptoPayment}
            />
          )}
      </div>
    )
  }

  // Use a traditional checkout form.
  return null
}

export default QuickPay
