/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import DefaultTOS from './DefaultTOS';

function CustomTOS({
  formErrors, textColor, customTOS, isV3, styles, onChange, name,
}) {
  const handleTOSClick = (event) => {
    onChange && onChange(event.target.checked);
  };

  if (!customTOS) {
    return (
      <DefaultTOS
        textColor={textColor}
        isV3={isV3}
        styles={styles}
      />
    );
  }

  return (
    <div>
      <div className={isV3 && styles.tosContainer}>
        <input
          id="customTOS"
          type="checkbox"
          name={name}
          className={isV3 && styles.checkbox}
          style={{
            transform: 'scale(1.5)',
            marginRight: '10px',
            verticalAlign: 'text-top',
          }}
          onClick={handleTOSClick}
        />
        <label
          className={isV3 && styles.tosLabel}
          style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
          htmlFor="tos"
        >
          By completing signup, I agree to both the terms and conditions of&nbsp;
          <a
            href="https://www.launchpass.com/terms"
            className={isV3 && styles.tosLink}
            style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
            target="_blank"
            rel="noreferrer"
          >
            LaunchPass
          </a>
          &nbsp;and the&nbsp;
          <a
            href={customTOS}
            className={isV3 && styles.tosLink}
            style={{ ...(!isV3 && { color: textColor, fontSize: 14 }) }}
            target="_blank"
            rel="noreferrer"
          >
            community
          </a>
          .
        </label>
      </div>

      {formErrors.customTOS && !isV3 && (
        <p
          style={{
            marginTop: '10px',
            marginBottom: '0px',
            visibility: 'visible',
            color: textColor,
          }}
        >
          You must accept the Terms &amp; Conditions
        </p>
      )}
    </div>
  );
}

export default CustomTOS;
